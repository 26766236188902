#landingPage {
    .ui.segment {
        margin: 0 !important;
    }

    .ui.button:not(.btnUp) {
        border-radius: 10px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .ui.primary.button {
        background-color: #FFCC00;
        color: #005A76;
        transition: 0.3s ease all;
    }

    .ui.primary.button:hover {
        background-color: #005A76;
        color: #fff;
    }

    .bannerWrapper {
        min-height: 100vh;
        background: url('./Assets/Images/banner.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding-top: 50px;
        padding-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .ui.tiny.image {
            position: absolute !important;
            top: 30px !important;
        }

        h1 {
            font-size: 67px;
            color: #fff;
            text-transform: capitalize;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }


    .galleryLAWrapper {
        padding: 0;
        min-height: 50vh;

        h2 {
            color: #0034BC;
        }

        .slick-list {
            padding: 60px 60px !important;
        }

        .slick-prev:before,
        .slick-next:before {
            color: #005A76 !important;
            font-size: 24px;
        }

        .slick-next,
        .slick-prev {
            z-index: 1;
        }

        .slick-next {
            right: 30px;
        }

        .slick-prev {
            left: 30px;
        }

        .slick-slide {
            padding: 10px;
        }

        .slick-slide>.ui.segment:active,
        .slick-slide>.ui.ui.segment:first-child:focus {
            border: 1px solid red !important;
        }

        .slick-slide.slick-active:not(.slick-current),
        .slick-slide:not(.slick-current) {

            // cursor: pointer;
            img {
                opacity: 0.5;
            }

            h2 {
                font-size: 14px;
                margin-left: 10px;
                opacity: 0.5;
            }
        }

        .slick-center.slick-current {
            -webkit-transform: scale(1.25);
            -moz-transform: scale(1.25);
            transform: scale(1.25);

            h2 {
                margin-left: 10px;
            }

            img {
                opacity: 1 !important;
            }
        }
    }

    .recruiterSnippetWrappper {
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #F4F4F4;

        .spacer-small {
            margin: 8px;
        }

        p {
            color: #808080;
            font-size: 24px;
        }

    }

    .hiringHardWrapper {
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #FF9F1C;
        min-height: 60vh;

        h1 {
            font-size: 64px;
            color: #fff;
        }

        p {
            font-size: 20px;
            color: #fff;
        }
    }

    .winTalentWrapper {
        padding-top: 100px;
        padding-bottom: 100px;
        min-height: 60vh;

        h1,
        p {
            color: #005A76;
            text-align: right;
        }

        h1 {
            font-size: 64px;
        }

        p {
            font-size: 20px;
        }
    }

    .bestCandidateWrapper {
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #2EC4B6;

        h1,
        p {
            color: #fff;
        }

        h1 {
            font-size: 64px;
        }

        p {
            font-size: 24px;
        }

        .ui.grid {
            margin-top: 30px;
        }

        .row,
        .visible.transition {
            display: flex !important;
            align-items: center;
            margin-bottom: 50px;
        }
    }

    .benefitWrapper {
        padding-top: 100px;
        padding-bottom: 70px;
        background-color: #EBEEFF;
        min-height: 60vh;

        h1,
        p {
            color: #005A76;
        }

        h1 {
            font-size: 67px;
        }

        p {
            font-size: 24px;
        }

        .benefitGrid {
            .visible.transition {
                display: flex !important;
            }

            .row {
                align-items: center !important;
                margin-bottom: 100px !important;
            }
        }
    }

    .joinUsWrapper {
        padding-top: 100px;
        padding-bottom: 100px;

        p {
            color: #005A76;
            font-size: 24px;
        }
    }
}

.privacyContent {
    h4 {
        margin-left: 20px !important;
    }
}

#opportunityPage {
    background-color: #F8F7FC;

    .opportunityToolbarWrapper {
        vertical-align: middle;
        justify-items: center;
        align-items: center;
        box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
    }

    .opportunity-logo-wrapper {
        align-items: center;
        display: flex;
        padding: 14px 14px 14px 14px;
    }

    @media only screen and (max-width: 819px) {
        .opportunity-logo-wrapper {
            align-items: center;
            display: flex;
            padding: 2px 2px 2px 2px;
        }
    }


    .opportunityToolbar {
        cursor: pointer;
        clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
        background: url('./Assets/Images/banner.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


    .recruiterSnippetWrappper {
        align-items: center;
        justify-content: center;


        .spacer-small {
            margin: 8px 8px 8px 8px;
        }

        p {
            color: #808080;
            font-size: 16px;
        }

    }


    .opportunityHeaderWrapper {
        min-height: 40vh;
        flex-direction: column;
        justify-content: center;
        display: flex;
        padding-top: 12rem;


        .top-wrapper {
            margin-top: 2em;
            margin-left: 2em;
            margin-right: 2em;
            text-align: center;

        }

        h1 {
            color: #005A76;
            font-size: 32px;
        }

        p {
            font-size: 24px;
        }

        .center-self {
            align-content: center;
        }

        .rounded-wrapper {
            margin-top: 13em;
            margin-bottom: 3em;
            margin-left: 0.5em;
            margin-right: 0.5em;
            background-color: #fff;
            border: 2px solid #ECE1E1;
            border-radius: 1rem;
            padding-top: 16px;
            padding-bottom: 16px;

            /* Extra small devices (phones, 600px and down) */
            @media only screen and (max-width: 819px) {
                margin-left: 0em;
                margin-right: 0em;
                background-color: #fff;
                border: 2px solid #ECE1E1;

            }



            .accordion {
                width: 100%;

                .stretched {
                    display: block;
                }

                .contact-button {
                    display: flex;
                    justify-content: flex-end;
                }

                .title {
                    font-size: 22px;
                    font-weight: 700;
                    color: #B6B6B6;

                    // i.caret.down.icon {
                    //     display: none;
                    // }
                }

                .content-margin {
                    margin-left: 24px;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                .summary {
                    p {
                        color: #625A5A;
                        font-size: 20px;
                    }
                }

                .title.active {
                    color: #000;
                    font-size: 22px;
                    font-weight: 700;

                    // i.caret.right.icon {
                    //     display: none;
                    // }
                }
            }


            .ui.accordion:not(.styled) .title~.content:not(.ui) {
                /* padding: .5em 0 1em; */
                padding: 0;
            }

            .ui.accordion .title~.content {
                display: block;
            }

            .expand {
                transition: .4s max-height ease-out, .4s opacity ease-out;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
            }

            .active.expand {
                max-height: 1000px;
                opacity: 1;
            }


            h2 {
                font-size: 24px;
            }
        }

        @media only screen and (max-width: 819px) {}
    }

    .ui.solveed-teal.button {
        background: transparent none !important;
        color: #10657E !important;
        font-weight: 400;
        font-size: 14px;
        border-radius: 0.28571429rem;
        text-transform: none;
        border: 1px solid;
        text-shadow: none !important;
        box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
    }

    .ui.solveed-teal.button:hover {
        background: #10657E !important;
        color: #fff !important;
        font-weight: 400;
        font-size: 14px;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
    }

    @media only screen and (max-width: 819px) {
        .ui.grid {


            .twelve.wide.column {
                width: 90% !important;
            }

            img {
                width: 50%;
                margin: auto;
            }
        }


    }



}

.ui.solveed-teal.button {
    background: transparent none !important;
    color: #10657E !important;
    font-weight: 400;
    font-size: 18px;
    border-radius: 0.28571429rem;
    text-transform: none;
    border: 1px solid;
    text-shadow: none !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
}

.ui.solveed-teal.button:hover {
    background: #10657E !important;
    color: #fff !important;
    font-weight: 400;
    font-size: 18px;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 819px) {
    .ui.huge.primary.button {
        font-size: 14px !important;
    }

    .bannerWrapper {
        min-height: 60vh !important;

        h1 {
            font-size: 26px !important;
        }
    }

    .galleryLAWrapper {
        min-height: 0vh !important;

        .ui.segment {
            h2 {
                font-size: 16px !important;
            }
        }
    }

    .hiringHardWrapper {
        min-height: 0vh !important;

        .ui.grid {

            .column,
            .five.wide.column {
                width: 100% !important;
            }

            img {
                width: 50%;
                margin: auto;
            }
        }

        h1 {
            font-size: 28px !important;
        }

        p {
            font-size: 16px !important;
        }
    }

    .winTalentWrapper {
        .ui.grid {

            .column,
            .seven.wide.column {
                width: 100% !important;
            }

            img {
                width: 80% !important;
                margin: auto;
            }
        }

        h1 {
            font-size: 28px !important;
        }

        p {
            font-size: 16px !important;
        }
    }

    .bestCandidateWrapper {
        .ui.grid {

            .column,
            .three.wide.column,
            .ten.wide.column {
                width: 100% !important;

                img {
                    width: 40% !important;
                    margin-bottom: 20px;
                }
            }
        }

        h1 {
            font-size: 28px !important;
        }

        p {
            font-size: 16px !important;
        }
    }

    .benefitWrapper {
        .benefitGrid {
            .row {
                padding: 0 !important;

                .column,
                .four.wide.column,
                .seven.wide.column {
                    width: 100% !important;

                    img {
                        width: 50% !important;
                        margin: 10px;
                    }
                }
            }
        }

        h1 {
            font-size: 28px !important;
        }

        p {
            font-size: 16px !important;
        }
    }

    .joinUsWrapper {
        p {
            font-size: 18px !important;
        }
    }
}

.footerWrapper {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #F2F2F2;

    .downloadList {
        a:first-child {
            img {
                margin-left: -8px !important;
                width: 120px;
            }
        }

        a:last-child {
            img {
                width: 103px;
            }
        }
    }
}

.footer2Wrapper {
    background-color: #F2F2F2;
    padding-bottom: 32px;

    .btnUp {
        position: absolute;
        right: 2%;
        top: -20px;
    }

    span {
        color: #005A76;
        font-weight: 700;
    }

    .icon {
        color: #000;

    }

    .icon:not(.btnUp) {
        transition: 0.3s ease all;
        opacity: 0.8;
        font-size: 20px;
    }

    .icon:hover {
        opacity: 1;
    }
}



@media only screen and (min-width: 820px) and (max-width: 991px) {
    #landingPage {

        .hiringHardWrapper,
        .winTalentWrapper {
            min-height: 0vh !important;
        }

        #secondBenefit {
            margin-left: 70px;
        }
    }
}

@media only screen and (min-width: 992px) {}